<template>
  <div class="contact">
    <div class="contact-text">
      <div>Arxhe Associates</div>
      <div>is always</div>
      <div>at your service.</div>
    </div>
    <div class="contact-info">
      <div class="column">
        <div class="item">Call Us</div>
        <div class="item">Contact our award-winning support team</div>
        <div class="item">+61 398176888</div>
        <div class="item">Service Hours: 9:00am~6:00pm Monday to Friday</div>
      </div>
      <div class="column">
        <div class="item">Email</div>
        <div class="item">yapei.xing@arxhe.com.au</div>
        <div class="item">&nbsp;</div>
        <div class="item">Service Hours: We're here to help 24/7</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contact",
};
</script>
<style lang="scss">
.contact {
  background-image: url("/images/church.png");
  background-repeat: no-repeat;
  background-size: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .contact-text {
    color: white;
    font-size: 30px;
    margin: 80px 0;
    width: var(--panel-width);
  }

  .contact-info {
    background-color: white;
    display: flex;
    align-items: flex-start;
    margin: 50px 0;
    width: var(--panel-width);

    .column {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      padding: 40px;

      .item {
        margin: 10px;
      }

      .item:nth-child(1) {
        color: var(--primary-color);
        font-size: 36px;
        flex: none;
      }

      .item:nth-child(2) {
        font-weight: bold;
      }

      .item:nth-child(3) {
        color: var(--primary-color);
      }
    }

    .column:nth-child(1) {
      border-right: 1px solid silver;
    }
  }
}
</style>